<template>
  <div>
    <b-overlay :show="loading" no-center :opacity="0.3" variant="light" rounded="sm">
      <template #overlay>
        <loading-spinner />
      </template>
      <div class="filter-icon" v-if="searchType == '4'" @click="filterShow = !filterShow">
        {{ $t('Filter') }}
        <b-img :src="iconFilter" class="ml-1"/>
      </div>
      <transition mode="out-in">
        <b-row v-if="filterShow && searchType == '4'" class="user-filters mb-4">
          <b-col sm="12">
            <h5>
              {{ $t('Country and Regions')}}
            </h5>
            <p>
              {{ $t('Please select at least one region to make the most suitable matches')}}
            </p>
          </b-col>
          <b-col class="filter-column" md="9" sm="12">
            <div class="filter-field">
              <b-form-group v-slot="{ ariaDescribedby }">
                <b-form-checkbox-group
                    v-model="selectedRegions"
                    :options="regions"
                    :aria-describedby="ariaDescribedby"
                ></b-form-checkbox-group>
              </b-form-group>
            </div>
          </b-col>
          <b-col md="3" sm="12" class="d-flex align-items-center justify-content-end">
            <b-button variant="primary" size="sm" @click="search">
              {{ $t('Search') }}
            </b-button>
          </b-col>
        </b-row>
      </transition>
      <b-row>
        <b-col v-if="message && users.length > 0" class="text-center mt-2" offset-xl="4" xl="4" offset-lg="4" lg="4" offset-md="3" md="6" sm="12">
          <slot>
            <h3 class="color-black">
              {{ messageTitle }}
            </h3>
            <p class="color-gray fs-16">
              {{ messageText }}
            </p>
          </slot>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-center login-button mb-2" cols="12">
          <router-link :to="{name: 'auth-login'}" v-if="searchType == '6'">
            {{ $t('Go to Login Page') }}
          </router-link>
        </b-col>
      </b-row>
      <b-row v-if="!users || users.length < 1">
        <discover-no-user v-if="!loading" />
      </b-row>
      <b-row v-else>
        <user-card v-for="user in users" :key="user.id" :user="user" :show-login-route="searchType == '6'"
                   @setLoading="setLoading($event)"/>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import { BRow, BCol, BButton, BFormGroup, BFormCheckboxGroup, BModal, BOverlay, BSkeleton, BSkeletonImg, BImg } from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import UserCard from '@/components/UserCard'
import DiscoverNoUser from '@/components/DiscoverNoUser'
import VueSlider from 'vue-slider-component'
import countries from '@/data/countries.json'
import vSelect from 'vue-select'
import _ from 'lodash'
import { throwDefaultError, showNotify } from '@/helpers/helpers'

// import { getUser } from '@/auth/utils'

export default {
  name: 'UserList',
  props: {
    camera: {
      type: Boolean,
      default: false
    },
    searchType: {
      type: String,
      default: "1" // 1: new users
    },
  },
  components: {
    DiscoverNoUser,
    UserCard,
    BButton,
    BCol,
    BFormGroup,
    BFormCheckboxGroup,
    BImg,
    BModal,
    BOverlay,
    BRow,
    BSkeleton,
    BSkeletonImg,

    vSelect,
    VueSlider
  },
  data () {
    return {
      countries: _.sortBy(countries, 'name').map(c => ({ ...c, name: this.$t(c.name) })),
      favoriteUsers: [],
      filter: {
        regions: []
      },
      filterShow: true,
      iconFilter: require('@/assets/images/icons/filter.svg'),
      imageGenderBoth: require('@/assets/images/genders/both.png'),
      imageGenderFemale: require('@/assets/images/genders/female.png'),
      imageGenderMale: require('@/assets/images/genders/male.png'),
      loading: true,
      loadingImage: require('@/assets/images/loading/loading-transparent.gif'),
      message: null,
      messageText: null,
      messageTitle: null,
      rangeOptions: {
        min: 18,
        max: 100,
        enableCross: false,
        dotOptions: {
          style: {
            backgroundColor: '#F9447A',
            border: '2px solid #fff',
            boxShadow: '0px 0px 14px rgba(0, 0, 0, 0.35)'
          }
        },
      },
      regions: [],
      sampleUser: {
        id: '30310f2612ef4092bb841a9a4d541349',
        userName: 'ozkanozdemir',
        profile: {
          onlineStatus: 'online',
          image: 'https://picsum.photos/200',
          country: 'TR',
          year: 1987
        },
      },
      searchQuery: '',
      selectedRegions: [],
      scrollOffsetForLoadMore: 100,
      users: [],
      usersLimit: 100,
      usersOffset: 0,
      usersAllLoaded: false,
    }
  },
  watch: {
    $route: {
      handler (value) {
        const userName = value.query.userName
        if (userName) {
          this.searchQuery = userName
          this.getUsers()
        }
      }, deep: true
    },
    selectedRegions(value, oldValue) {
      if (oldValue.length === 1 && value.length > 1 && oldValue[0] === 1) {
        this.$nextTick(() => {
          this.selectedRegions.shift()
        })
      } else if (value.length > 1 && value.includes(1)) {
        this.$nextTick(() => {
          this.selectedRegions = [1]
        })
      }
    }
  },
  created () {
    // Scroll'u dinle
    window.addEventListener('scroll', this.handleScroll)

    // Search type 4 (near by) ise regions'ları çek
    if (this.searchType == '4') {
      this.getRegions()
    }

    this.getUsers()
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    getFavoriteUsers () {
      useJwt.favoriteUsers(0, 100)
          .then(response => {
            try {
              this.favoriteUsers = response.data.body.users
            } catch (e) {

            }
          })
          .catch(error => {})
    },
    getRegions () {
      useJwt.nearByRegions()
          .then(response => {
            console.log('nearByRegions', response)
            this.regions = response.data.body.map(r => ({ text: this.$t(r.name), value: r.id }))
          })
          .catch(error => {
            throwDefaultError(this.$toast, error)
          })
    },
    getUsers (clear = true, regions = null) {
      if (clear) {
        this.users = []
        this.usersOffset = 0
        this.usersAllLoaded = false
      }
      this.loading = true

      const _filter = {}
      if (typeof this.searchQuery === 'string' && this.searchQuery !== '') {
        _filter['userName'] = this.searchQuery
      }

      const _limit = this.searchType == '6' ? null : this.usersLimit;
      const _offset = this.searchType == '6' ? null : this.usersOffset;

      if (regions && Array.isArray(regions) && regions.length > 0) {
        _filter['regionIds'] = regions
      }

      useJwt.usersList(_offset, _limit, this.searchType, _filter)
          .then(response => {
            try {
              if (this.usersOffset === 0 && this.searchType == '3' && response.data.body.users.length <= 0) {
                this.searchType = '2'
                this.getUsers()
                this.message = true
                this.messageTitle = this.$t("It looks like you haven't added any profiles to your like list yet.")
                this.messageText = this.$t("We have listed some profiles for you below. Enjoy Boho Live.")
                return;
              }

              if (clear) {
                this.users = response.data.body.users
                if (this.users.length <= 0) {
                  showNotify(this.$toast, this.$t('User not found'), 'info')
                }
              } else {
                this.users.push(...response.data.body.users)
              }

              // Offset ayarla
              this.usersOffset = response.data.body.offset + response.data.body.limit

              if (this.usersOffset >= response.data.body.totalCount) {
                this.usersAllLoaded = true
              }
            } catch (e) {
              throwDefaultError(this.$toast)
            }
          })
          .catch(error => {
            throwDefaultError(this.$toast, error)
          })
          .finally(() => {
            this.loading = false;
            if (this.searchType == '4' && regions.length > 0)
              this.filterShow = false;
          })
    },
    handleScroll (event) {
      if ((window.innerHeight + window.scrollY + this.scrollOffsetForLoadMore) >= document.body.scrollHeight &&
          !this.usersAllLoaded && this.searchType != '6') {
        this.loadMore()
      }
    },
    loadMore() {
      if (!this.loading) {
        this.getUsers(false)
      }
    },
    search() {
      if (this.searchType == '4' && this.selectedRegions.length > 0) {
        this.getUsers(true, this.selectedRegions)
      }
    },
    setLoading($event) {
      this.loading = $event
    },
    updateSearchCriteria(data) {
      useJwt.updateSearchCriteria(data)
    }
  }
}
</script>

<style lang="scss">
/* Set the theme color of the component */
$themeColor: #F9447A;

/* import theme style */
@import '~vue-slider-component/lib/theme/default.scss';
</style>

<style lang="scss" scoped>
.login-button {
  a {
    font-size: 24px !important;
    text-decoration: underline;
  }
}

.filter-icon {
  display: flex;
  justify-content: end;
  align-items: center;
  font-family: 'Lexend', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  cursor: pointer;
  margin-bottom: 1rem;
}
.enter { transform: translateX(100%) }
.enter-to { transform: translateX(0) }
.slide-enter-active { position: absolute }

.leave { transform: translateX(0) }
.leave-to { transform: translateX(-100%) }

.slide-enter-active,
.slide-leave-active { transition: all 750ms ease-in-out }
</style>

<style lang="scss">
.bv-no-focus-ring {
  list-style: none;
  padding: 8px 20px;

  div {
    padding-left: 0 !important;
  }
}
.bv-no-focus-ring label{
  display: inline-block;
  background-color: rgba(255, 255, 255, .9);
  border: 2px solid rgba(139, 139, 139, .3);
  color: #adadad;
  border-radius: 4px;
  white-space: nowrap;
  margin: 3px 0px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  transition: all .2s;
}

.bv-no-focus-ring label {
  padding: 8px 12px;
  cursor: pointer;
}

.bv-no-focus-ring label::before {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 12px;
  padding: 2px 6px 2px 2px;
  content: "\f067";
  transition: transform .3s ease-in-out;
}

.bv-no-focus-ring input[type="checkbox"]:checked + label::before {
  content: "\f00c";
  transform: rotate(-360deg);
  transition: transform .3s ease-in-out;
}

.bv-no-focus-ring input[type="checkbox"]:checked + label {
  border: 2px solid #E94EBC;
  background-color: #E94EBC;
  color: #fff;
  transition: all .2s;
}
.bv-no-focus-ring input[type="checkbox"], .bv-no-focus-ring label::before, .bv-no-focus-ring label::after {
  display: none;
  position: absolute !important;
  opacity: 0 !important;
}
.bv-no-focus-ring input[type="checkbox"]:focus + label {
  border: 2px solid #e9a1ff;
}
.message {
  padding: 20px;
  text-align: center;

  h2 {
    font-size: 26px;
    color: #666;
    font-family: 'Lexend', sans-serif;
  }
  p {
    font-size: 20px;
    color: #999;
    font-family: 'Lexend', sans-serif;
  }
}
</style>
